import StringField from "@/shared/fields/string-field";
import { validators } from '@/shared/form/validators';

export default class EmailField extends StringField {
    forFormRules() {
        let output = {};

        output = {
            ...output,
            ...validators.emailAddress(),
        };

        if (this.required) {
            output = {
                ...output,
                ...validators.required(),
            };
        }

        return output;
    }
}
